// 下拉选择项目机会或项目
<template>

    <div class="comSelectProjectOrReserveProject">
        <el-select v-model="value"
                   popper-class="optsBox"
                   class="fakeSelect"
                   :placeholder="label ? '' : '请选择项目/机会'">
            <div slot="prefix">
                <span class="ellipsis">
                    {{label}}
                </span>
                <div class="cleanBtn"
                     :class="label?'el-icon-circle-close':'el-icon-arrow-down'"
                     @click.stop="clean"></div>
            </div>
            <el-option style="height:auto;">
                <div class="selectOptsBox">
                    <div class="searchBox"
                         @click.stop>
                        <div class="typeBox">
                            <el-radio-group v-model="searchForm.reserveProjectFlag"
                                            @change="changeReserveProjectFlag">
                                <el-radio-button :label="false">项目</el-radio-button>
                                <el-radio-button :label="true">项目机会</el-radio-button>
                            </el-radio-group>
                        </div>
                        <el-form :model="searchForm"
                                 ref="searchForm"
                                 :inline="true"
                                 @click.stop>
                            <el-form-item prop="name"
                                          :style="{width: !searchForm.reserveProjectFlag ? '49.3%' : '32.5%'}">
                                <el-input v-model="searchForm.name"
                                          placeholder="请输入项目名称"
                                          clearable></el-input>
                            </el-form-item>
                            <el-form-item prop="companyId"
                                          :style="{width: !searchForm.reserveProjectFlag ? '49.3%' : '32.5%'}">
                                <customer-or-provider-select-tree ref="selectTree"
                                                                  placeholderStr="请选择客户"
                                                                  :isAppendToBody="false"
                                                                  type="CUSTOMER"
                                                                  :shouldClean="true"
                                                                  @handClear="handClear"
                                                                  @treeSelectNodeClick="selectTreeCustClick"></customer-or-provider-select-tree>
                            </el-form-item>
                            <el-form-item prop="status"
                                          v-if="searchForm.reserveProjectFlag">
                                <el-select v-model="searchForm.status"
                                           popper-class="noPersonalize"
                                           placeholder="请选择机会状态"
                                           :popper-append-to-body="false"
                                           clearable>
                                    <el-option v-for="item in itemstatusOpts"
                                               :key="item.code"
                                               :label="item.label"
                                               :value="item.code">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="tableBox">
                        <el-table :data="tableData.data"
                                  :show-header="false"
                                  @current-change="handleCurrentChange"
                                  height="200"
                                  stripe>
                            <el-table-column prop="name"
                                             align="left">
                            </el-table-column>
                            <el-table-column prop="name"
                                             v-if="searchForm.reserveProjectFlag"
                                             align="right">
                                <template slot-scope="scope">
                                    <el-link type="success"
                                             v-if="scope.row.status.text === '跟进中'">跟进中</el-link>
                                    <el-link type="warning"
                                             v-if="scope.row.status.text === '挂起'">挂起</el-link>
                                    <el-link type="danger"
                                             v-if="scope.row.status.text === '战败'">战败</el-link>
                                    <el-link type="info"
                                             v-if="scope.row.status.text === '放弃'">放弃</el-link>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                </div>

            </el-option>
        </el-select>
    </div>

</template>

<script>
import CustomerOrProviderSelectTree from "../../components/selectTree/customerOrProviderSelectTree";
import { Loading } from "element-ui";
import selectOpts from "@/api/selectOpts.js";
import commonAPI from "@/api/commonAPI.js";

export default {
    name: "comSelectProjectOrReserveProject",
    model: {
        prop: "value",
        event: "change",
    },
    props: {
        // v-model 双向绑定：接受父组件的值
        value: {
            type: String,
            default: "",
        },
        // 默认回显数据
        defaultLabel: {
            type: String,
            default: null,
        },
        // true为项目机会 false为项目
        reserveProjectFlag: {
            default: false,
        },
        // 需要的状态 归档
        stage: {
            default: null,
        },
        // 项目机会下拉需要排除的下拉 ['成功']
        outOpt: {
            type: Array,
            default() {
                return [];
            },
        },
    },

    components: {
        CustomerOrProviderSelectTree,
    },

    data() {
        return {
            projectId: "",
            label: "", // 回显的项目名称
            searchForm: {
                reserveProjectFlag: false,
                name: "", // 项目名称
                companyId: null,
                status: null,
            },
            itemstatusOpts: [], // 机会状态下拉
            tableData: {
                data: [],
            },
        };
    },

    created() {},

    mounted() {
        // 获取项目机会下拉
        this.getOpts();
    },

    methods: {
        // 获取项目机会下拉
        getOpts() {
            commonAPI.getSelectByCode("ReserveProjectStatus").then((res) => {
                let respData = [{ code: "", label: "全部" }].concat(
                    res.content
                );
                if (this.outOpt && this.outOpt.length) {
                    respData = respData.filter((item) => {
                        return this.outOpt.indexOf(item.label) === -1;
                    });
                }
                this.itemstatusOpts = respData;
            });
        },
        // 获取列表
        getList() {
            if (this.searchForm.reserveProjectFlag) {
                // 获取项目机会下拉列表
                this.getReserveProjectOpts();
            } else {
                // 获取项目机会下拉列表
                this.getProjectOpts();
            }
        },
        // 获取项目机会下拉列表
        getReserveProjectOpts() {
            let loading = Loading.service({
                target: document.querySelector(".optsBox"),
            });
            let data = Object.assign({}, this.searchForm, {
                customerCompanyId: this.searchForm.companyId,
            });
            if (this.stage) {
                data.reserveProjectStatus = this.stage;
            }
            selectOpts
                .reserveProjectOpts(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    // 回显数据
                    this.showData();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 获取项目下拉列表
        getProjectOpts() {
            let loading = Loading.service({
                target: document.querySelector(".optsBox"),
            });
            let data = Object.assign({}, this.searchForm, {
                customerCompanyId: this.searchForm.companyId,
            });
            if (this.stage) {
                data.projectStatus = this.stage;
            }
            selectOpts
                .projectList(data)
                .then((res) => {
                    loading.close();
                    this.tableData.data = res.content.records;
                    // 回显数据
                    this.showData();
                })
                .catch(() => {
                    loading.close();
                });
        },
        // 目标客户的选择
        selectTreeCustClick(node) {
            this.searchForm.companyId = node.id;
            this.getList();
        },
        // 目标客户的清空
        handClear() {
            this.searchForm.companyId = "";
        },
        // 切换项目还是项目机会
        changeReserveProjectFlag() {
            this.$emit(
                "changeReserveProjectFlag",
                this.searchForm.reserveProjectFlag
            );
            // 获取列表
            this.getList();
        },
        // 选中表格
        handleCurrentChange(val) {
            this.$emit("change", val.id);
        },
        // 清空选中数据
        clean() {
            this.label = null;
            this.projectId = null;
            this.$emit("change", null);
        },
        // 回显数据
        showData() {
            this.projectId = this.value;
            if (this.value) {
                if (this.defaultLabel) {
                    this.label = this.defaultLabel;
                } else {
                    let filterObj = this.tableData.data.filter((item) => {
                        return item.id === this.value;
                    });
                    this.label =
                        filterObj.length > 0 ? filterObj[0].name : null;
                }
            }
        },
        stop(e) {
            window.event
                ? (window.event.cancelBubble = true)
                : e.stopPropagation();
            return;
        },
    },

    computed: {},

    watch: {
        // 监听表单
        searchForm: {
            deep: true,
            immediate: true,
            handler: function () {
                this.getList();
            },
        },
        // 接收到父组件的value
        value: {
            deep: true,
            immediate: true,
            handler: function () {
                // 回显数据
                this.showData();
            },
        },
        // 接收到父组件的reserveProjectFlag
        reserveProjectFlag() {
            this.searchForm.reserveProjectFlag =
                this.reserveProjectFlag || false;
        },
    },
};
</script>

<style lang="scss" scoped>
.el-select-dropdown__item:not(.noPersonalize .el-select-dropdown__item) {
    height: auto !important;
    width: 710px;
    padding: 0;
}
.el-form-item {
    margin-bottom: 5px;
}
.searchBox {
    padding: 10px 20px 0;
    .typeBox {
        margin-bottom: 10px;
        .el-radio-group {
            width: 100%;
            label {
                width: 50%;
                ::v-deep .el-radio-button__inner {
                    width: 100% !important;
                }
            }
        }
    }
    ::v-deep .el-form-item__content {
        width: 100%;
    }
}
.fakeSelect ::v-deep input {
    color: #fff !important;
}
.fakeSelect ::v-deep .el-input__inner {
    padding-left: 15px;
}
.tableBox {
    padding: 10px;
    background: #fff;
}
::v-deep .el-select__caret {
    display: none;
}
.searchBox ::v-deep .el-select__caret {
    display: block;
}
::v-deep .el-input__prefix {
    color: #606266;
    padding-left: 15px;
    min-width: 205px;
    width: calc(100% - 15px);
    text-align: left;
    .ellipsis {
        width: 185px;
    }
    .cleanBtn {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        color: #c0c4cc;
    }
}
::v-deep .el-scrollbar {
    max-height: 300px;
}
</style>

<style>
.el-select-dropdown__wrap {
    max-height: none !important;
}
</style>